import React from "react"
import { Container, Layout, SEO, Grid, TextRow, Tag } from "../../components"

const FrontHub = () => (
  <Layout hideProfile>
    <SEO title="Project: Front Hub - Internal Frontend Framework - RD Station" />
    <Container>
      <Grid>
        <TextRow>
          <h1>Front Hub - Internal Frontend Framework - RD Station</h1>
          <Tag>Javascript</Tag>
          <Tag>ReactJS</Tag>
          <Tag>NodeJS</Tag>
          <Tag>Shell</Tag>
          <Tag>Jest</Tag>
          <Tag>React Testing Library</Tag>
          <Tag>Prettier</Tag>
          <Tag>ESLint</Tag>
          <Tag>Webpack</Tag>

          <p>
            Thinking about growing fast and consistently, we decided to have a
            micro frontend structure that uses the same tool, code standards and
            have a team dedicated to taking care of this core.
          </p>

          <p>
            I am contributing as a <strong>Frontend Engineer</strong> to create
            new features and tools for the frontend developers. Our focus on{" "}
            <a
              href="https://front-hub.rdstation.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              Front Hub
            </a>{" "}
            is resolving common problems like interoperability, routing,
            testing, localization, instrumentation, feature-flag, communication,
            quality assurance and performance.
          </p>
        </TextRow>
      </Grid>
    </Container>
  </Layout>
)

export default FrontHub
